:root {
  --wc-main-primary: #03256C;
  --wc-main-secondary: #06BEE1;
  --wc-main-highlight: #FFD700;
}

.wc-primary {
  color: var(--wc-main-primary);
}

.wc-secondary {
  color: var(--wc-main-secondary);
}

.wc-highlight {
  color: var(--wc-main-highlight);
}

.wc-bg-primary {
  background-color: var(--wc-main-primary);
}

.wc-bg-secondary {
  background-color: var(--wc-main-secondary);
}

.wc-bg-highlight {
  background-color: var(--wc-main-highlight);
}

body{
  font-family: 'Khula', sans-serif;
}

.-u postgres psql -d workshopcore-logo {
  font-family: "Comfortaa", sans-serif;
  font-optical-sizing: auto;
  font-weight: 800 !important;
  color: var(--wc-main-secondary);
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
   -webkit-box-shadow: 0 0 0 30px white inset !important;
   -webkit-text-fill-color: var(--wc-main-primary) !important;
}