.picker {
  position: relative;
  margin-right: 1em;
}

.swatch {
  width: 28px;
  height: 28px;
  border-radius: 8px;
  border: 3px solid #fff;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.popover {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 9px;
  z-index: 9999;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.popover-right{
  right: calc(100% + 2px);
}

.popover-left{
  left: calc(100% + 2px);
}


.flexContainer{
  display: -webkit-flex; /* Safari */
  display: flex; /* Standard syntax */
}

.flex-container .column{
  padding: 10px;
  background: #dbdfe5;
  -webkit-flex: 1; /* Safari */
  -ms-flex: 1; /* IE 10 */
  flex: 1; /* Standard syntax */
}
