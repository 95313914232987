html, body {
  height: 100%;
  margin: 0;
  /* overflow: hidden; */
}

#wizardRoot {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* wizardContainer should be flexible and take up remaining space */
.wizardContainer {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center; 
  padding: 16px;
}

.fullSize {
  width: 100%;
  min-height: 100%;
}

.formWrapperCard {
  margin: 110px 20% 80px 20%;
  height: 100%;

  @media (max-width: 960px) {
    margin: 0em;
  }
}

.wizardBtn {
  margin-top: 0.75em;
}

.custom-file-input:lang(en)~.custom-file-label::after {
  content: "Buscar" !important;
}
